import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, reactive, ref } from "vue";

interface Props {
  data: any[];
  xTitle?: string;
  yTitle?: string;
  colors?: string[];
  seriesName?: string;
  barsPadding?: number;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BarChart',
  props: {
    data: {},
    xTitle: {},
    yTitle: {},
    colors: {},
    seriesName: {},
    barsPadding: {}
  },
  setup(__props: any) {

const props = __props;
const { data, xTitle, yTitle, colors, barsPadding, seriesName } =
  reactive(props);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const anychart = require("anychart/dist/js/anychart-cartesian.min.js");

const el = ref(null);
let chart = reactive<any>(null);

onMounted(() => {
  chart = anychart.column();
  chart.background(null);

  chart.barGroupsPadding(barsPadding);

  const series = chart.column(data);
  series.name(seriesName);
  const key_colors: any = {
    "10%": "#E8F0E6",
    "20%": "#D1E0CD",
    "30%": "#B9D1B4",
    "40%": "#A2C29B",
    "50%": "#8BB381",
    "60%": "#74A368",
    "70%": "#5D944F",
    "80%": "#458536",
    "90%": "#2E751D",
    "100%": "#176604",
  };

  series.fill((value: any) => {
    return value.x && value.x !== -1 && key_colors[value.x];
  });

  if (colors) {
    series.selected().fill(colors[1], 0.5);
    series.normal().stroke(colors[1], 1, "solid");
    series.hovered().stroke(colors[1], 1, "solid");
    series.selected().stroke(colors[1], 1, "solid");
  }
  yTitle && chart.yAxis().title(yTitle);
  xTitle && chart.xAxis().title(xTitle);

  const axisLabels = chart.xAxis().labels();

  axisLabels.format((value: any) => {
    switch (value.value) {
      case "10%":
        return "0%-10%";
      case "30%":
        return "20%-30%";
      case "50%":
        return "40%-50%";
      case "70%":
        return "60%-70%";
      case "90%":
        return "80%-90%";
      default:
        return "";
    }
  });

  chart.tooltip().titleFormat((value: any) => {
    switch (value.x) {
      case "10%":
        return "RE: 0%-10%";
      case "20%":
        return "RE: 10%-20%";
      case "30%":
        return "RE: 20%-30%";
      case "40%":
        return "RE: 30%-40%";
      case "50%":
        return "RE: 40%-50%";
      case "60%":
        return "RE: 50%-60%";
      case "70%":
        return "RE: 60%-70%";
      case "80%":
        return "RE: 70%-80%";
      case "90%":
        return "RE: 80%-90%";
      case "100%":
        return "RE: > 90%";
      default:
        return "";
    }
  });

  chart.container(el.value).draw();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-full mx-auto",
    ref_key: "el",
    ref: el
  }, null, 512))
}
}

})