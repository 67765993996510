import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { generateBodyScript } from "@/utils/helpers/importExternalScript";
import { onMounted, reactive, ref } from "vue";

interface Props {
  data: any[];
  linearColor: {
    start: string;
    end: string;
  };
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Maps',
  props: {
    data: {},
    linearColor: {}
  },
  setup(__props: any) {

const props = __props;
const { data, linearColor } = reactive(props);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const anychart = require("anychart/dist/js/anychart-map.min.js");

const el = ref(null);
let chart = reactive<any>(null);

onMounted(async () => {
  await generateBodyScript(
    "https://cdn.anychart.com/geodata/2.1.1/custom/world/world.js"
  );

  // create a Doughnut pie chart and set the data
  chart = anychart.map();
  chart.background(null);

  // create choropleth series
  const series = chart.choropleth(data);

  // set geoIdField to 'id', this field contains in geo data meta properties
  series.geoIdField("id");

  // set map color settings
  // series.colorScale(
  //   anychart.scales.linearColor("#5E5E5E", "#BAABA8", linearColor.end)
  // );
  // series.hovered().fill("#cfc4bf");

  // set the colors and ranges for the scale
  // series.colorScale(
  //   anychart.scales.linearColor(linearColor.start, linearColor.end)
  // );
  series.colorScale(
    anychart.scales.ordinalColor([
      { from: 0, to: 10, color: "#C74349" },
      { from: 10, to: 20, color: "#DA734B" },
      { from: 20, to: 30, color: "#EEA44E" },
      { from: 30, to: 40, color: "#F6CA53" },
      { from: 40, to: 50, color: "#D4C862" },
      { from: 50, to: 60, color: "#B1C772" },
      { from: 60, to: 70, color: "#8EC582" },
      { from: 70, to: 80, color: "#6CBD7F" },
      { from: 80, to: 90, color: "#49B378" },
      { greater: 90, color: "#29A971" },
    ])
  );

  series.fill((value: any) => customFunctionsFillData(value));
  //set the single hue progression
  //const colors = anychart.color.singleHueProgression(linearColor.end);

  // define the colors
  //series.colorScale().colors(colors);

  // create and enable the colorRange
  const colorRange = chart.colorRange();
  colorRange.enabled(true);
  colorRange.labels().format((value: any) => {
    switch (value.value) {
      case "0 - 10":
        value.colorRange.name = "0% - 10%";
        break;
      case "10 - 20":
        value.colorRange.name = "10% - 20%";
        break;
      case "20 - 30":
        value.colorRange.name = "20% - 30%";
        break;
      case "30 - 40":
        value.colorRange.name = "30% - 40%";
        break;
      case "40 - 50":
        value.colorRange.name = "40% - 50%";
        break;
      case "50 - 60":
        value.colorRange.name = "50% - 60%";
        break;
      case "60 - 70":
        value.colorRange.name = "60% - 70%";
        break;
      case "70 - 80":
        value.colorRange.name = "70% - 80%";
        break;
      case "80 - 90":
        value.colorRange.name = "80% - 90%";
        break;
      default:
        value.colorRange.name = "90% - 100%";
        break;
    }
    return value.colorRange.name;
  });
  // change the default marker type to diamond
  const marker = colorRange.marker();
  marker.type("diamond");

  // change the default marker color to purple
  marker.fill(linearColor.end);

  // set the size to your marker
  // marker.size(7);

  // series.name("Sales 2015");

  // chart.legend(true);

  // set the source mode of the legend and add styles
  chart
    .legend()
    .itemsSourceMode("categories")
    .position("right")
    .align("top")
    .itemsLayout("vertical")
    .padding(50, 0, 0, 20)
    .paginator(false);

  series
    .tooltip()
    .format(({ value }: any) =>
      value === undefined ? "No Facility" : `value: ${value}`
    );

  // set geo data, you can find this map in our geo maps collection
  // https://cdn.anychart.com/#maps-collection
  chart.geoData(anychart.maps["world"]);

  chart.container(el.value).draw();
});
const customFunctionsFillData = (value: any) => {
  const findDataEmpty = data.find((x, index) => index === value.index);
  if (
    findDataEmpty &&
    !Object.prototype.hasOwnProperty.call(findDataEmpty, "value")
  ) {
    return "#5E5E5E";
  }
  return value.scaledColor;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-full mx-auto",
    ref_key: "el",
    ref: el
  }, null, 512))
}
}

})