import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, reactive, ref } from "vue";

interface Props {
  data: any[];
  data_2?: any[];
  data_3?: any[];
  yTitle?: string;
  colors?: string[];
  seriesName?: string;
  lineMarker?: string;
  colors_2?: string[];
  colors_3?: string[];
  seriesName_2?: string;
  seriesName_3?: string;
  percentChart?: number;
  legendStyle?: any;
  legendLayout?: string;

  withExtraYScale?: boolean;
  lineAllowExtraYScale?: boolean;
  isColorBaseLine?: boolean;
  isBaseYear?: boolean;
  isChartXScroll?: boolean;
  baseYear?: number;
  maxValue?: number;
  barsPadding?: number;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'LineChart',
  props: {
    data: {},
    data_2: {},
    data_3: {},
    yTitle: {},
    colors: {},
    seriesName: {},
    lineMarker: {},
    colors_2: {},
    colors_3: {},
    seriesName_2: {},
    seriesName_3: {},
    percentChart: {},
    legendStyle: {},
    legendLayout: {},
    withExtraYScale: { type: Boolean },
    lineAllowExtraYScale: { type: Boolean },
    isColorBaseLine: { type: Boolean },
    isBaseYear: { type: Boolean },
    isChartXScroll: { type: Boolean },
    baseYear: {},
    maxValue: {},
    barsPadding: {}
  },
  setup(__props: any) {

const props = __props;
const {
  data,
  colors,
  data_2,
  data_3,
  colors_2,
  colors_3,
  yTitle,
  seriesName,
  seriesName_2,
  seriesName_3,
  legendStyle,
  legendLayout,
  withExtraYScale,
  percentChart,
  isChartXScroll,
  isColorBaseLine,
  isBaseYear,
  baseYear,
  maxValue,
  barsPadding,
  lineMarker,
} = reactive(props);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const anychart = require("anychart/dist/js/anychart-cartesian.min.js");

const el = ref(null);
let chart = reactive<any>(null);

onMounted(() => {
  // create a Doughnut pie chart and set the data
  chart = anychart.line();
  chart.background(null);

  chart.barsPadding(barsPadding);

  chart.barGroupsPadding(2);

  const series = chart.spline(data);
  series.name(seriesName);
  series.hovered().markers().enabled(true).type(lineMarker).size(4);
  if (maxValue) {
    let yScale = chart.yScale();
    yScale.maximum(maxValue);
  }
  // adding extra Y scale
  const extraYScale = anychart.scales.linear();
  extraYScale.minimum(0);
  extraYScale.maximum(percentChart || 100);
  extraYScale.ticks().interval(20);

  if (colors && colors?.length > 1) {
    series.normal().fill({
      keys: colors,
      // set angle of colors drawing
      angle: -90,
    });
    series.selected().fill(colors[1], 0.5);
    series.normal().stroke(colors[1], 1, "solid");
    series.hovered().stroke(colors[1], 1, "solid");
    series.selected().stroke(colors[1], 1, "solid");
    series
      .tooltip()
      .format(({ value }: any) => anychart.format.number(value, 3, ".", ","));
    if (isColorBaseLine) {
      series.fill(coloringFunction);
    }
  }

  if (data_2) {
    const series2 = chart.column(data_2);

    seriesName_2 && series2.name(seriesName_2);

    if (colors_2 && colors_2?.length > 1) {
      series2.normal().fill({
        keys: colors_2,
        // set angle of colors drawing
        angle: -90,
      });
      series2.selected().fill(colors_2[1], 0.5);
      series2.normal().stroke(colors_2[1], 1, "solid");
      series2.hovered().stroke(colors_2[1], 1, "solid");
      series2.selected().stroke(colors_2[1], 1, "solid");
      series2
        .tooltip()
        .format(({ value }: any) => anychart.format.number(value, 3, ".", ","));
    }
  }
  if (data_3) {
    const series3 = chart.column(data_3);

    seriesName_3 && series3.name(seriesName_3);

    if (colors_3 && colors_3?.length > 1) {
      series3.normal().fill({
        keys: colors_3,
        // set angle of colors drawing
        angle: -90,
      });
      series3.selected().fill(colors_3[1], 0.5);
      series3.normal().stroke(colors_3[1], 1, "solid");
      series3.hovered().stroke(colors_3[1], 1, "solid");
      series3.selected().stroke(colors_3[1], 1, "solid");
      series3
        .tooltip()
        .format(({ value }: any) => anychart.format.number(value, 3, ".", ","));
    }
  }
  yTitle && chart.yAxis().title(yTitle);

  if (withExtraYScale) {
    // adding and adjust extra Y axis
    const extraYAxis = chart.yAxis(1);

    extraYAxis.stroke(null);
    extraYAxis.ticks().enabled(false);

    extraYAxis.orientation("right");
    extraYAxis.labels().format("{%value}%");

    extraYAxis.scale(extraYScale);
  }

  if (isChartXScroll) {
    chart.xScroller(true);
    //chart.xZoom().setTo(0, 0.3);
  }

  chart.yGrid().enabled(true);
  chart.yGrid().stroke({
    color: "#fff",
    thickness: 2,
    opacity: 0.1,
  });

  legendStyle && chart.legend(legendStyle);
  legendLayout && chart.legend().itemsLayout(legendLayout);
  if (isBaseYear) {
    chart.legend().itemsFormatter(function (legendItems: any) {
      legendItems.unshift({
        text: "Base Year",
        iconType: "square",
        iconFill: "#CCFF66",
        //disabled: true,
        iconEnabled: true,
      });
      return legendItems;
    });
  }
  chart.legend().listen("legendItemClick", (e: any) => {
    e.preventDefault();
  });
  //Format label left
  const extraYAxisLeft = chart.yAxis(0);
  extraYAxisLeft
    .labels()
    .format(({ value }: any) => anychart.format.number(value, 3, ".", ","));

  chart.animation(true);

  chart.yAxis().stroke(null);
  chart.yAxis().ticks().enabled(false);
  chart.xAxis().ticks().enabled(false);
  chart.container(el.value).draw();
});

const coloringFunction = (that: any) => {
  let x = that.x;
  if (x == baseYear) return "#CCFF66";
  if (x == `${baseYear} Base Year`) return "#CCFF66";
  if (x != baseYear) return colors;
  return that.sourceColor;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-full mx-auto",
    ref_key: "el",
    ref: el
  }, null, 512))
}
}

})