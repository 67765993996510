import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { generateBodyScript } from "@/utils/helpers/importExternalScript";
import { onMounted, reactive, ref } from "vue";

interface Props {
  data: any[];
  country: string;
  withLegend?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AreaMap',
  props: {
    data: {},
    country: {},
    withLegend: { type: Boolean }
  },
  emits: ["handleClickMarker"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { data, country, withLegend } = reactive(props);

const emits = __emit;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const anychart = require("anychart/dist/js/anychart-map.min.js");

const el = ref(null);
let chart = reactive<any>(null);

onMounted(async () => {
  await Promise.all([
    generateBodyScript(
      "https://cdnjs.cloudflare.com/ajax/libs/proj4js/2.3.15/proj4.js"
    ),
    generateBodyScript(
      `https://cdn.anychart.com/geodata/2.1.1/countries/${country}/${country}.js`
    ),
  ]);

  // create a Map chart and set the data
  chart = anychart.map();
  chart.background(null);

  chart.geoData(anychart.maps[country]);

  // Creates the marker series
  const createSeries = (name: any, data: any[], color: any) => {
    const series = chart.marker(data).name(name);

    series.legendItem({
      iconType: "circle",
      iconFill: color,
      iconStroke: "2 #E1E1E1",
    });

    // sets Tooltip for series
    series
      .tooltip()
      .useHtml(true)
      .title(false)
      .separator(false)
      .fontSize(12)
      .format((data: any) => {
        return `
        <div class="w-28 h-20 rounded p-1 bg-white">
          <img class="w-full h-full object-fill object-center bg-cover" src="${data.getData(
            "url"
          )}" alt="${data.getData("name")}"/>
        </div>`;
      });

    // sets settings for marker series
    series
      .selectionMode("none")
      .stroke("2 #E1E1E1")
      .fill(color)
      .size(6)
      .labels(false);

    series.normal().size(6).labels(false);
    series.hovered().size(6).labels({
      fontColor: "black",
      background: "#fff",
    });
    series.selected().size(6).labels({
      fontColor: "black",
      background: "#fff",
    });

    series.normal().stroke(null);
    // series.hovered().stroke(null);
    // series.selected().stroke(null);

    // // set the size of ACME markers

    // series.hovered().fill({
    //   src: "https://img.icons8.com/color/100/000000/marker.png",
    //   mode: "fit",
    // });
    // series.selected().fill({
    //   src: "https://img.icons8.com/color/100/000000/marker.png",
    //   mode: "fit",
    // });
    series
      .normal()
      .type("circle")
      .fill({
        src: require("@/assets/images/map_marker.png"),
      });

    // series.hovered().stroke("3 #E1E1E1").fill("white").size(6);
  };

  data.forEach((item) => {
    if (item.red) {
      createSeries(item.name, [item], "#b62202");
    } else {
      createSeries(item.name, [item], "#92d050");
    }
  });

  chart.legend().enabled(withLegend);
  chart.legend().position("bottom");

  chart.unboundRegions().enabled(true).fill("#808080").stroke("#808080");

  // chart.listen("pointClick", (e: any) => {
  //   emits("handleClickMarker", data[e.series.getIndex()].id);
  // });

  chart.legend().listen("legendItemClick", (e: any) => {
    e.preventDefault();
  });

  chart.container(el.value).draw();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-full mx-auto",
    ref_key: "el",
    ref: el
  }, null, 512))
}
}

})