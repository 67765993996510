import { defineComponent as _defineComponent } from 'vue'
import { Ref, ref, onMounted, onUpdated, reactive, toRefs } from "vue";

interface Props {
  isLoading: boolean;
  rounded?: number | string;
  duration?: number | string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseBorderLoader',
  props: {
    isLoading: { type: Boolean },
    rounded: {},
    duration: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { rounded = 0, duration = 4 } = reactive(props);
const { isLoading } = toRefs(props);

const el = ref(null as unknown) as Ref<HTMLElement>;
const isShowing = ref(true);

onMounted(() => {
  isShowing.value = !isLoading.value;
  if (el.value.offsetParent) {
    const { width = 0, height = 0 }: DOMRect =
      el.value.offsetParent.getBoundingClientRect();
    const MIN = 100;
    const MAX = 300;
    const end = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN;
    const perimeter = (width + height) * 2;
    const stop = perimeter - end;

    el.value.style.setProperty("--perimeter-duration", `${duration}s`);
    el.value.style.setProperty("--perimeter-full", `${perimeter}`);
    el.value.style.setProperty("--perimeter-stop", `${stop}`);
    el.value.style.setProperty("--perimeter-end", `${end}`);
  }
});

onUpdated(() => {
  if (!isLoading.value && el.value) {
    el.value.style.setProperty("--perimeter-end", `${0}`);
    el.value.style.setProperty("--perimeter-duration", `${0}s`);

    setTimeout(() => {
      isShowing.value = false;
    }, 200);
  }
});

const __returned__ = { props, rounded, duration, isLoading, el, isShowing, Ref, ref, onMounted, onUpdated, reactive, toRefs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})