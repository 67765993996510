import HttpClient from "@/services/axios/instance";

import { catchAxiosError } from "@/services/axios/error";

import { IResponse } from "@/services/axios/entities";
import { ICreateSiteForm, IParameters, ISiteModel } from "@/models/Site";
import { getEnv } from "@/utils/storage";

const FACILITY = "FacilityMonitoring";
const env = getEnv();
export class SiteAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public createSite = async (site: ICreateSiteForm): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(FACILITY, site)
      .catch(catchAxiosError);

    return response;
  };

  public getSites = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(FACILITY)
      .catch(catchAxiosError);

    return response;
  };

  public getSitesFacilities = async (
    FacilityId: string,
    params: IParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/facilities`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public saveSitesFacility = async (
    FacilityId: string | number,
    data: any
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${FACILITY}/${FacilityId}/facilities`, data)
      .catch(catchAxiosError);

    return response;
  };

  public updateFacility = async (
    SiteId: number,
    FacilityId: number,
    data: any
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${FACILITY}/${SiteId}/facilities/${FacilityId}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public deleteFacility = async (
    SiteId: string | number,
    FacilityId: number
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .delete(`${FACILITY}/${SiteId}/facilities/${FacilityId}`)
      .catch(catchAxiosError);
    return response;
  };

  public getSitesPvForecast = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/pv-forecast`)
      .catch(catchAxiosError);
    return response;
  };

  public getSitesOperationsAlarm = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/operations-alarm`)
      .catch(catchAxiosError);
    return response;
  };

  public getSitesRenewablePerformance = async (
    FacilityId: string,
    year: number
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/renewable-performance/${year}`)
      .catch(catchAxiosError);
    return response;
  };

  public getSitesCarbonEmissionsByScope = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/carbon-emissions-by-scope`)

      .catch(catchAxiosError);
    return response;
  };

  public getSitesPerformanceOverview = async (
    FacilityId: string,
    keyQuery: string,
    params?: ISiteModel
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/performance-overview/${keyQuery}`, {
        params,
      })

      .catch(catchAxiosError);
    return response;
  };

  public getSitesLoadPerformance = async (
    FacilityId: string,
    keyQuery: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/load-performance/${keyQuery}`)

      .catch(catchAxiosError);
    return response;
  };

  public getSitesSystemPerformance = async (
    FacilityId: string,
    keyQuery: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/system-performance/${keyQuery}`)

      .catch(catchAxiosError);
    return response;
  };

  //Green House gases
  public getSitesGreenHouseGasesAvoided = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/green-house-gases-avoided`)
      .catch(catchAxiosError);
    return response;
  };
  // Overview
  public getOverviewGeneration = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/overview-generation`)
      .catch(catchAxiosError);
    return response;
  };

  public getOverviewConsumption = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/overview-consumption`)
      .catch(catchAxiosError);
    return response;
  };

  public getOverviewEnergyStorage = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/overview-energy-storage`)
      .catch(catchAxiosError);
    return response;
  };

  public getSitesFacilitySummaryInfo = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/summary`)
      .catch(catchAxiosError);
    return response;
  };

  public getFacilityMonitoring = async (params: any): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${params.year}`)
      .catch(catchAxiosError);
    return response;
  };
}
