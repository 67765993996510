import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full h-full absolute left-0 top-0",
  ref: "el"
}
const _hoisted_2 = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  class: "w-full h-full"
}
const _hoisted_3 = ["rx"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.isShowing)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createElementBlock("svg", _hoisted_2, [
          _createElementVNode("rect", {
            class: _normalizeClass(['w-full h-full ', _ctx.styles['border-loading']]),
            rx: $setup.rounded
          }, null, 10, _hoisted_3)
        ]))
      ], 512))
    : _createCommentVNode("", true)
}