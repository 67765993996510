import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, reactive, ref } from "vue";

interface Props {
  data: any[];
  title?: string;

  legendStyle?: any;
  legendLayout?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DoughnutChart',
  props: {
    data: {},
    title: {},
    legendStyle: {},
    legendLayout: {}
  },
  setup(__props: any) {

const props = __props;
const { data, title, legendStyle, legendLayout } = reactive(props);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const anychart = require("anychart/dist/js/anychart-pie.min.js");
const el = ref(null);
let chart = reactive<any>(null);

onMounted(() => {
  // create a Doughnut pie chart and set the data
  chart = anychart.pie(data);
  chart.background(null);

  chart.innerRadius("80%");

  // set the chart title
  chart.legend().itemsLayout("vertical");

  // set the position of labels
  chart.labels({
    fontColor: "#000",
    fontSize: 12,
    position: "inside",
  });

  // configure connectors
  chart.overlapMode(true);
  chart.insideLabelsOffset("-150%");
  // adjust chart settings
  const chartTitle = chart.legend().title();
  // enable title
  chartTitle.enabled(true);
  // set title text
  chartTitle.text(title ? title : "");
  chartTitle.fontColor("#baaa77");
  // place title into bottom of the chart
  chartTitle.orientation("top");
  chartTitle.padding([0, 0, 10, 0]);
  // stick title to the left side
  chartTitle.align("left");

  // chart.title(title).orientation("bottom");

  legendStyle && chart.legend(legendStyle);
  legendLayout && chart.legend().itemsLayout(legendLayout);

  chart.animation(true);

  chart.container(el.value).draw();

  // if (!chart && props.options) {
  //   chart = new anychart.fromJson(props.options);
  //   chart.container(el.value).draw();
  // }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-full mx-auto",
    ref_key: "el",
    ref: el
  }, null, 512))
}
}

})